import React from 'react';
import ContactForm from './components/ContactForm';


function App() {
  return (
    <div className="App">
      <ContactForm />
    </div>
  );
}

export default App;
