import React, { useState } from "react";
import { useForm, ValidationError } from "@formspree/react";
import { FaDumbbell } from "react-icons/fa"; // Import for the barbell icon
import "./Animations.css"; // Import animations

function ContactForm() {
  const [state, handleSubmit] = useForm("mrbgplwq");
  const [phoneNumber, setPhoneNumber] = useState("");

  // Function to format phone number as (XXX) XXX-XXXX
  const formatPhoneNumber = (value) => {
    // Remove all non-numeric characters
    const cleaned = value.replace(/\D/g, "");

    // Format according to the pattern (XXX) XXX-XXXX
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    if (match) {
      const formattedNumber = [
        match[1] ? `(${match[1]}` : "",
        match[2] ? `) ${match[2]}` : "",
        match[3] ? `-${match[3]}` : "",
      ]
        .join("")
        .trim();
      return formattedNumber;
    }
    return value;
  };

  const handlePhoneChange = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setPhoneNumber(formattedPhoneNumber);
  };

  if (state.succeeded) {
    return (
      <div
        className="min-h-screen flex items-center justify-center"
        style={{
          backgroundImage: `url('/background.webp')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="bg-black bg-opacity-90 p-8 rounded-md flex flex-col items-center justify-center shadow-lg text-center">
          <p className="text-orange-500 font-pixel animate-fade-in text-4xl mb-4">
            Thank you for joining
          </p>
          <h1 className="text-glitch font-pixel text-4xl">Dont Develop</h1>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col items-center pt-40 bg-black text-white overflow-hidden">
      {/* Absolute header at the top */}
      <p className="absolute bottom-5 text-orange-500 font-pixel text-sm text-center w-full">
        made by dans2177
      </p>

      {/* Glitchy title */}
      <h1 className="text-glitch font-pixel text-3xl mb-5">
        Dont Develop
      </h1>

      {/* Form container */}
      <div className="w-11/12 md:w-1/2 lg:w-1/3 p-5 border-2 border-retroPurple shadow-lg bg-gray-900 bg-opacity-90">
        {/* Next release box */}
        <div className="flex flex-col mb-5  shadow-md bg-retroDark bg-opacity-90 p-3 relative">
          <span className="text-orange-600 font-pixel text-xs absolute top-2 left-2">
            Next release:
          </span>
          <div className="flex items-center justify-center mt-5">
            <span className="text-orange-500 font-pixel text-lg">Gym app</span>
            <FaDumbbell className="text-orange-500 text-2xl ml-2" />
          </div>
        </div>

        {/* Form */}
        <form onSubmit={handleSubmit} className="space-y-4">
          <label htmlFor="email" className="block text-orange-500 font-pixel">
            Email:
          </label>
          <input
            id="email"
            type="email"
            name="email"
            className="w-full p-2 border-2 border-retroPurple bg-black text-white font-pixel"
            required
            pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
            title="Please enter a valid email address."
          />
          <ValidationError
            prefix="Email"
            field="email"
            errors={state.errors}
            className="text-retroRed font-pixel text-sm"
          />

          <label htmlFor="phone" className="block text-orange-500 font-pixel">
            Phone Number:
          </label>
          <input
            id="phone"
            type="tel"
            name="phone"
            value={phoneNumber}
            onChange={handlePhoneChange}
            className="w-full p-2 border-2 border-retroPurple bg-black text-white font-pixel"
            required
            pattern="\(\d{3}\)\s\d{3}-\d{4}"
            title="Please enter a valid phone number in the format (XXX) XXX-XXXX."
          />
          <ValidationError
            prefix="Phone"
            field="phone"
            errors={state.errors}
            className="text-retroRed font-pixel text-sm"
          />

          <button
            type="submit"
            disabled={state.submitting}
            className="w-full py-2 bg-retroPurple text-white font-pixel hover:bg-retroOrange transition"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default ContactForm;
